import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { CheckCheckIcon } from 'lucide-react';
import { useDescope } from '@descope/react-sdk';
import { LOGIN_URL, POST_LOGIN_REDIRECT_PATHNAME } from '../../constants';

function VerifyLogin() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('t');
  const code = searchParams.get('code');
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const navigate = useNavigate();

  const descope = useDescope();

  const verifyToken = useCallback(
    async (token?: string | null, code?: string | null) => {
      if (token) {
        const { data, ok, error } = await descope.enchantedLink.verify(token);

        if (!ok && error) {
          setError(error.errorMessage ?? error.errorDescription);
          setTimeout(() => {
            navigate(LOGIN_URL);
          }, 3000);
        }

        if (ok && data) {
          setSuccess(true);
          setTimeout(() => {
            window.close();
          }, 2000);
        }
      } else if (code) {
        const { data, ok, error } = await descope.saml.exchange(code);
        if (!ok && error) {
          setError(error.errorMessage ?? error.errorDescription);
          setTimeout(() => {
            navigate(LOGIN_URL);
          }, 500);
        }

        if (ok && data) {
          setSuccess(true);
          navigate(POST_LOGIN_REDIRECT_PATHNAME);
        }
      }
    },
    [descope]
  );

  useEffect(() => {
    if (token || code) {
      verifyToken(token, code);
    }
  }, [token, code]);
  return (
    <div className=" h-full w-full flex justify-center items-center">
      {error ? (
        <p className=" text-red-500 text-lg">
          {error}. Redirecting you to the login screen to try again...
        </p>
      ) : null}
      {success ? (
        <p className=" text-lime-400 flex gap-4 items-center animate-pulse">
          <CheckCheckIcon className="w-11 h-11" />
          Your have been Logged In! Closing this window to continue...
        </p>
      ) : null}
    </div>
  );
}

export default VerifyLogin;
