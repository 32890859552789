import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

// Define a type for the slice state
interface policyState {
  selectedConditionTab: string;
}

// Define the initial state using that type
const initialState: policyState = {
  selectedConditionTab: 'metCondition',
};

export const policySlice = createSlice({
  name: 'policy',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setSelectedConditionTab: (state, action: PayloadAction<string>) => {
      const selectedConditionTab = action.payload;
      state.selectedConditionTab = selectedConditionTab;
    },
  },
});

export const { setSelectedConditionTab } = policySlice.actions;

export default policySlice.reducer;
