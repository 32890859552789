import { useMutation, useQuery } from '@tanstack/react-query';

import { queryClientRQ } from 'root/src/App';
import { restClient } from 'root/src/api';
import { IntegrationType } from './IntegrationItem';

export interface Connection {
  url: string;
  user: string;
  api_key: string;
}
interface UseIntegrationsProps {
  type: IntegrationType;
}
interface IntegrationResponse {
  connection: Connection | undefined;
  isLoading: boolean;
  setConnection?: (payload: any) => Promise<void>;
  connecting?: boolean;
  disconnecting?: boolean;
  unSetConnection?: () => Promise<void>;
}

type UseIntegrationsResponse = IntegrationResponse;

const useIntegrations = ({
  type,
}: UseIntegrationsProps): UseIntegrationsResponse => {
  if (type === IntegrationType.JIRA) {
    const { data, isLoading } = useQuery({
      queryKey: ['get-connections'],
      queryFn: () =>
        restClient.get<Connection>(`/api/v1/integrations/jira/config`),
    });

    const { mutateAsync: setUpConnection, isPending: connecting } = useMutation(
      {
        mutationKey: ['update-connection'],
        mutationFn: (req: { url: string; user: string; api_key: string }) =>
          restClient.post<void>(`/api/v1/integrations/jira/config`, {
            url: req.url,
            user: req.user,
            api_key: req.api_key,
          }),
        onSuccess: () => {
          queryClientRQ.invalidateQueries({ queryKey: ['get-connections'] });
        },
      }
    );

    const { mutateAsync: deleteConnection, isPending: disconnecting } =
      useMutation({
        mutationKey: ['update-connection'],
        mutationFn: () =>
          restClient.delete<void>(`/api/v1/integrations/jira/config`),
        onSuccess: () => {
          queryClientRQ.invalidateQueries({ queryKey: ['get-connections'] });
        },
      });

    const setConnection = async (payload: {
      url: string;
      user: string;
      api_key: string;
    }) => {
      const { url, user, api_key } = payload;
      await setUpConnection({ url, user, api_key });
    };

    const unSetConnection = async () => {
      await deleteConnection();
    };

    return {
      connection: data?.data,
      isLoading,
      setConnection,
      connecting,
      disconnecting,
      unSetConnection,
    };
  }

  return {
    connection: undefined,
    isLoading: false,
    setConnection: undefined,
    connecting: false,
    unSetConnection: undefined,
    disconnecting: undefined,
  };
};

export default useIntegrations;
