import { configureStore } from '@reduxjs/toolkit';
import policySlice from 'src/pages/Policies/newPolicy/policySlice';
import trustFeedSlice from 'src/pages/TrustFeed/trustFeedSlice';
import onboardingSlice from 'src/pages/onboarding/onboardingSlice';

const reduxStore = configureStore({
  reducer: {
    trustFeed: trustFeedSlice,
    onboarding: onboardingSlice,
    policy: policySlice,
  },
});

export default reduxStore;
