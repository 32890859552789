import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { IOnboardEntity } from './types';

// Define a type for the slice state
interface onboardingState {
  selectedRow: IOnboardEntity | null;
  selectedRowId: string;
}

// Define the initial state using that type
const initialState: onboardingState = {
  selectedRow: null,
  selectedRowId: '',
};

export const onboardingSlice = createSlice({
  name: 'onboarding',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setSelectedRow: (state, action: PayloadAction<IOnboardEntity | null>) => {
      state.selectedRow = action.payload;
      state.selectedRowId = action.payload?.id || '';
    },
  },
});

export const { setSelectedRow } = onboardingSlice.actions;

export default onboardingSlice.reducer;
