import { BrowserRouter } from 'react-router-dom';
import { AuthProvider as DescopeAuthProvider } from '@descope/react-sdk';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App.tsx';
import { ThemeProvider } from './styles/ThemeProvider.tsx';
import store from './contexts/redux/store.ts';

// const mockAllowedEnvs = ['development'];
async function enableMocking() {
  // if (!mockAllowedEnvs.includes(process.env.NODE_ENV ?? '')) {
  //   return;
  // }
  // const { worker } = await import('./mocks/browser');
  // return worker.start();
}

enableMocking().then(() => {
  ReactDOM.createRoot(document.getElementById('root')!).render(
    <Provider store={store}>
      <ThemeProvider defaultTheme="dark" storageKey="singulr-ui-theme">
        <BrowserRouter>
          <DescopeAuthProvider
            projectId={import.meta.env.VITE_DESCOPE_PROJECT_ID}
          >
            <App />
          </DescopeAuthProvider>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
});
