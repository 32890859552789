import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { createContext, useContext, useMemo } from 'react';
import { useSession } from '@descope/react-sdk';
import { EntityType, EntityTypesReponse } from '../types/EntityTypesReponse';

import { EnumTypesResponse } from '../types/EnumTypesResponse';
import axios from '../api/restClient';

type AppConfigProviderProps = {
  children: React.ReactNode;
};

type AppConfigProviderState = {
  entityTypes: EntityType[];
  enumLabels: EnumTypesResponse;
  loading: boolean;
};

const initialState: AppConfigProviderState = {
  entityTypes: [],
  enumLabels: [],
  loading: false,
};

const AppConfigProviderContext =
  createContext<AppConfigProviderState>(initialState);

export function AppConfigProvider({
  children,
  ...props
}: AppConfigProviderProps) {
  const { isSessionLoading, isAuthenticated } = useSession();
  const { data: entityTypesResponse, isLoading: entityTypesLoading } = useQuery<
    UseQueryResult<EntityTypesReponse>
  >({
    queryKey: ['app-config', 'entity-types'],
    queryFn: async () => axios.get(`/api/v1/schemas/ui/entity-types`),
    enabled: isAuthenticated && !isSessionLoading,
    retry: 5,
    retryDelay: 500,
  });

  const { data: enumLabelsResponse, isLoading: enumTypesLoading } = useQuery<
    UseQueryResult<EnumTypesResponse>
  >({
    queryKey: ['app-config', 'enum-types'],
    queryFn: async () => axios.get(`/api/v1/schemas/ui/enum-types`),
    enabled: isAuthenticated && !isSessionLoading,
    retry: 5,
    retryDelay: 500,
  });

  const value = useMemo(
    () => ({
      entityTypes: entityTypesResponse?.data ?? [],
      enumLabels: enumLabelsResponse?.data ?? [],
      loading: entityTypesLoading || enumTypesLoading,
    }),
    [
      entityTypesResponse,
      enumLabelsResponse,
      entityTypesLoading,
      enumTypesLoading,
    ]
  );

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <AppConfigProviderContext.Provider {...props} value={value}>
      {children}
    </AppConfigProviderContext.Provider>
  );
}

export const useAppConfig = () => {
  const appConfig = useContext(AppConfigProviderContext);

  if (appConfig === undefined) {
    throw new Error('useAppConfig must be used within a AppConfigProvider');
  }

  return appConfig;
};
