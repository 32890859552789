/* eslint-disable no-console */
import { getSessionToken } from '@descope/react-sdk';
import axios, { InternalAxiosRequestConfig } from 'axios';
import { DefaultApi } from '../generated';
import { getBaseUrl } from './restClient';

const baseUrl = await getBaseUrl();
const baseURL = `${baseUrl}/api/v1`;
const apiClient = axios.create({ baseURL });

apiClient.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  const token = getSessionToken();

  return {
    ...config,
    headers: { ...config.headers, Authorization: `Bearer ${token}` },
  } as unknown as InternalAxiosRequestConfig;
});
const allApi = new DefaultApi(
  {
    basePath: baseURL,
    isJsonMime: () => true,
  },
  baseURL,
  apiClient
);
export default allApi;
