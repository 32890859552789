import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

interface ITrustRowItem {
  id: string;
  isSelected: boolean;
  entityType: number;
  name: string;
}

interface TrustFeedState {
  cartItems: Record<string, ITrustRowItem>;
  entityTypeMap: Record<string, number>;
  entityIdMap: Record<number, string>;
  selectedLineage: string;
}

const initialState: TrustFeedState = {
  cartItems: {},
  entityTypeMap: {},
  entityIdMap: {},
  selectedLineage: '',
};

export const trustFeedSlice = createSlice({
  name: 'trustFeed',
  initialState,
  reducers: {
    addToCart: (state, action: PayloadAction<ITrustRowItem>) => {
      const payload = action.payload;
      state.cartItems[payload.id] = payload;
    },
    clearSelectedItems: (state) => {
      state.cartItems = {};
    },
    setEntityTypeMap: (
      state,
      action: PayloadAction<{
        entityTypeMap: Record<string, number>;
        entityIdMap: Record<number, string>;
      }>
    ) => {
      const { entityTypeMap, entityIdMap } = action.payload;
      state.entityTypeMap = entityTypeMap;
      state.entityIdMap = entityIdMap;
    },
    setSelectedLineage: (state, action: PayloadAction<string>) => {
      state.selectedLineage = action.payload;
    },
  },
});

export const {
  addToCart,
  setEntityTypeMap,
  clearSelectedItems,
  setSelectedLineage,
} = trustFeedSlice.actions;

export default trustFeedSlice.reducer;
