import axios, { InternalAxiosRequestConfig } from 'axios';

import { getSessionToken } from '@descope/react-sdk';

let baseUrl: string = import.meta.env.VITE_BASE_URL;
export const getBaseUrl = async (): Promise<string> => {
  if (baseUrl) {
    return baseUrl;
  }
  try {
    const response = await fetch('/config.json');
    const data = await response.json();
    baseUrl = data?.base_url ?? '';
    return baseUrl;
  } catch (error) {
    console.error('Error loading config.json');
    return '';
  }
};

const baseURL = await getBaseUrl();

const apiClient = axios.create({ baseURL });
apiClient.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  const token = getSessionToken();
  return {
    ...config,
    headers: { ...config.headers, Authorization: `Bearer ${token}` },
  } as unknown as InternalAxiosRequestConfig;
});
export default apiClient;
